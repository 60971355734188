<template>
  <div class="page" style="margin:0">
    <van-skeleton title :row="23" :loading="loading">
      <!-- 顶部搜索 -->
      <div class="classify_top flex-r-s-c">
        <van-search v-model="serValue" class="classify_ser" placeholder="请输入搜索关键词" readonly shape="round"
                    @click="$router.push('/product/searchproList_cart?name= ')"
        />
        <div class="classify_kefu flex-r-c-c" @click="openKeFu">
          <van-icon name="service-o" size="0.5rem" />
          <span>客服</span>
        </div>
      </div>
      <div class="top_classfly_tab top_classfly_tab_cart" style="margin-top:-0.2rem">
        <van-tabs v-model="activeKeyTop" @click="onChangeTab">
          <van-tab v-for="(top,index_top) in topList" :key="index_top" :title="top.title" />
        </van-tabs>
      </div>
      <!-- 分类商品 -->
      <div class="classify_pro_list flex-r-s-s">
        <div class="classify_right_tab" style="width:2.3rem" :style="{height:bodyHeigt+'px'}">
          <div v-for="(siderFirst,index_siderFirst) in siderFirstList" :key="index_siderFirst"
               :class="firtIndex === index_siderFirst ?'right_tab_first right_tab_first_actived':'right_tab_first' "
               style="padding-left:0.2rem;padding-right:0.1rem;"
          >
            <div :class="firtIndex === index_siderFirst ? 'right_tab_first_name right_tab_first_name_actived':'right_tab_first_name'"
                 @click="firstChange(siderFirst,index_siderFirst)"
            >{{ siderFirst.title }}</div>
            <template v-if="firtIndex === index_siderFirst">
              <div v-for="(siderSecond,index_siderSecond) in siderFirst.siderSecondList" :key="index_siderSecond"
                   :class="secondIndex === index_siderSecond ? 'right_tab_first_second right_tab_first_second_actived':'right_tab_first_second'"
              >
                <div :class="secondIndex === index_siderSecond ? 'right_tab_first_second_name right_tab_first_second_name_actived':'right_tab_first_second_name'"
                     @click="secondChange(siderSecond,index_siderSecond)"
                >{{ siderSecond.title }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="classify_pro_list_left flex-r-s-c" style="width:7.5rem;overflow-x: hidden;">
          <div class="left_pro_list" :style="{height:bodyHeigt+'px'}">
            <!-- 顶部过滤 -->
            <div class="serTopFilter_box" style="width:7.5rem;overflow-x: hidden;">
              <serTopFilter ref="serTopFilter" :cid="proSearch.proCategoryId" :type="5" :showtag="false"
                            @changeFilter="changeFilter" @popupSearch="popupSearch"
              />
            </div>
            <div name="list" style="margin-top:-0.3rem">
              <MyscrollList ref="myList01" :loading="listLoading" :list="proList" :line-type="1" @myload="proload">
                <template #default="slotProps">
                  <proCard03 :id="slotProps.index" :length="proList.length" :info="slotProps.item" :cannum="slotProps.item.canBuyNum" class="proCard03"
                             @changeNum="changeNum"
                  />
                </template>
              </MyscrollList>
            </div>
          </div>
        </div>
      </div>
    </van-skeleton>
    <!-- 底部操作 -->
    <div class="classify_sub_cart flex-r-sa-c">
      <div class="classify_sub_cart_num" @click="toView">
        <van-image v-if="cartNum > 0" width="1.39rem" height="1.39rem"
                   src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart11.png"
        />
        <van-image v-else width="1.39rem" height="1.39rem"
                   src="https://oss.xiaoyi120.com/shop2.0/product/classify_cart10.png"
        />
        <div v-if="cartNum > 0" :class="cartNum > 9 ? 'nums_posite2':'nums_posite1' ">{{ cartNum }}</div>
      </div>
      <div class="classify_sub_cart_info flex-c-s-s">
        <div class="info_money">实付金额:<span style="color:#E22D1D">￥</span><span
          style=" font-size: 0.48rem;color:#E22D1D"
        >{{ totalMoney }}</span></div>
        <div class="info_money2">优惠减￥{{ discountPrice }}</div>
      </div>
      <div />
      <div />
      <div />
      <div />
      <div :class="cartNum > 0 ? 'classify_sub_cart_button1':'classify_sub_cart_button0'" @click="toView">订单预览</div>
    </div>
    <!-- 全部分类的遮盖 -->
    <div v-show="show" class="left_pro_list_all" @click="show = false" />
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />

    <!-- 资质认证弹窗 -->
    <van-dialog v-model="certificationShow" :show-confirm-button="false">
      <div class="mask flex-r-c-c">
        <div class="certification_dialog flex-c-c-c">
          <van-image width="4.11rem" height="2.75rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification.png"
          />
          <div class="certification_title"> 您尚未提交认证资料 </div>
          <div class="certification_tip"> 暂时无法采购 </div>
          <div class="flex-r-sa-c">
            <van-button class="loginOut_btn" round type="info" plain color="#0767ab" @click="loginOut">重新登录
            </van-button>
            <van-button class="certification_btn" round type="info" color="#0767ab" @click="toUpCertification">立即认证
            </van-button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import './index.scss'
import MyscrollList from '@/components/MyscrollList'
import proCard03 from '@/components/pro_card_03_cart'
import kefuBtn from '@/components/kefu_btn'
import serTopFilter from '@/components/ser_top_filter'
import { mallPreferential_categoryList, mallPreferential_productList, mallPreferential_cartCalculate } from '@/api/pro'
import { user_certificationDecide } from '@/api/user'
export default {
  name: 'ProClassify',
  components: { MyscrollList, proCard03, kefuBtn, serTopFilter },
  data() {
    return {
      bodyHeigt: '',
      value1: '',
      loading: true,
      show: false,
      topList: [],
      serValue: '',
      activeKey: 0,
      activeKeyTop: 0,
      firtIndex: 0,
      secondIndex: 0,
      siderFirstList: [{ siderSecondList: [] }],
      rightTitle: '',
      showPopover: false,
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 10, proCategoryId: '' },
      proList: [],
      listLoading: false,

      cartNum: 0,
      discountPrice: '0.00',
      totalMoney: '0.00',

      certificationShow: false
    }
  },
  created() {
    console.log('created 首次被加载', window.name)
    if (window.name === 'proDetail' || window.name === '') {
      console.log('created 首次被加载')
      window.name = 'proDetail' // 在首次进入页面时我们可以给window.name设置一个固定值
      sessionStorage.removeItem('jump')
      var path = this.$route.path
      sessionStorage.setItem('shareUrl', path)
    } else if (window.name === 'isReload') {
      sessionStorage.setItem('jump', true)
    }
    console.log('created 进入classify0')
    this.getSideData() // 获取侧边分类
    this.getNum()
    var clientHeight = `${document.documentElement.clientHeight}`
    this.bodyHeigt = (clientHeight * 1 - 200).toFixed(2)
  },
  activated() {
    console.log(sessionStorage.getItem('cart_pro_id'))
    this.getNum()
    console.log('keepalive 进入classify1')
    if (sessionStorage.getItem('tabberJump')) {
      this.loading = true
      // this.$router.go(0)
      // window.location.reload()
      sessionStorage.removeItem('tabberJump')
      this.getSideData() // 获取侧边分类
      var clientHeight = `${document.documentElement.clientHeight}`
      console.log('clientHeight1', clientHeight) // 1.6  1.4 2 = 7 * 37.5 = 262.5
      this.bodyHeigt = (clientHeight * 1 - 200).toFixed(2)
      console.log('clientHeight2', this.bodyHeigt) // 1.6  1.4 2 = 5 * 37.5 = 187.5
    } else {
      var id = sessionStorage.getItem('classify_pro_id')
      document.getElementById(id).scrollIntoView()
    }
  },
  deactivated() {
    this.$refs.serTopFilter.closePullBox()
    console.log('keepalive 离开classify')
  },
  methods: {
    loadNowProduct() {
      var cart_pro_id = JSON.parse(sessionStorage.getItem('cart_pro_id'))
      console.log(sessionStorage.getItem('cart_pro_id'))
      var productId = cart_pro_id.productId
      var num = cart_pro_id.num
      console.log(productId, num)
      for (const i in this.proList) {
        if (this.proList[i].productId === productId) { this.proList[i].num = num }
      }
      this.$nextTick(() => { sessionStorage.removeItem('cart_pro_id') })
    },
    getNum() {
      mallPreferential_cartCalculate().then(res => {
        this.cartNum = res.data.typeNum
        this.discountPrice = res.data.discountPrice
        this.totalMoney = res.data.totalPrice
      })
      user_certificationDecide().then(res => {
        this.certificationDecide = res.data.certificationDecide
        this.certificationShow = res.data.certificationDecide !== 1
      })
      if (sessionStorage.getItem('cart_pro_id')) { this.loadNowProduct() }
    },
    getSideData() {
      mallPreferential_categoryList({ pid: '3' }).then(res => {
        this.topList = res.data
        this.onChangeTab(0)
      })
    },
    // 重新加载商品更新事件
    reloadProList() {
      this.proList = []
      this.siderFirstList = []
      this.proSearch.pageNum = 1
    },
    // 顶部切换
    onChangeTab(index) {
      this.reloadProList()
      const nextId = this.topList[index].id
      mallPreferential_categoryList({ pid: nextId, type: 'top' }).then(res => {
        this.loading = false
        this.siderFirstList = res.data
        this.proSearch.proCategoryId = res.data[0].id
        this.firstChange(res.data[0], 0)
        this.$refs.myList01.load_status_clear()
        this.$refs.serTopFilter.closePullBox()
      })
    },
    // 侧边一级切换
    firstChange(item, index) {
      console.log('closePullBox 1')
      this.firtIndex = index
      mallPreferential_categoryList({ pid: item.id, type: 'sider' }).then(res => {
        item.siderSecondList = res.data
        this.secondChange(res.data[0], 0)
        this.$refs.serTopFilter.closePullBox()
        this.$refs.myList01.load_status_clear()
      })
    },
    // 侧边二级切换
    secondChange(item, index) {
      console.log('closePullBox 2')
      this.$refs.serTopFilter.closePullBox()
      this.$refs.myList01.load_status_clear()
      this.secondIndex = index
      this.proSearch.proCategoryId = item.id
      this.rightTitle = item.title
      this.proList = []
      this.proSearch.pageNum = 1
      console.log('this.rightTitle', this.rightTitle)
      this.popupSearch({ tagId: '', proBrands: [] })
    },
    // 加载商品
    loadPro(index) {
      mallPreferential_productList(this.proSearch).then(res => {
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.loadPro()
        // 加载状态结束
      }, 50)()
    },

    // 修改商品数量
    changeNum(num) { this.getNum() },
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) },
    changeFilter(e) {
      this.proSearch.pageNum = 1
      console.log(e)
      if (e === 1 || e === 41) {
        this.proSearch.orderType = ''
        this.proSearch.orderWay = ''
        this.proSearch.tagId = ''
      }
      if (e === 2) {
        this.issaleNum = !this.issaleNum
        this.proSearch.orderType = 'SALE_NUM'
        this.proSearch.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''
      }
      if (e === 3) {
        this.isprice = !this.isprice
        this.proSearch.orderType = 'PRICE'
        this.proSearch.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.proSearch.tagId = ''
      }
      if (e === 4) {
        this.$refs.serTopFilter.rightTitle(this.rightTitle)
      }
      if (e === 42) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '282'
      }
      if (e === 43) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '281'
      }
      if (e === 44) {
        this.proSearch.orderType = ''; this.proSearch.orderWay = ''
        this.proSearch.tagId = '275'
      }
      this.proList = []
      this.loadPro()
    },
    popupSearch(item) {
      this.$refs.myList01.load_status_clear()
      console.log(item)
      this.proSearch.pageNum = 1
      this.proSearch.tagId = item.tagId
      this.proSearch.proBrands = item.proBrands
      this.proList = []
      this.loadPro()
    },
    toView() {
      if (this.cartNum > 0) {
        let url = ''
        url = `/order/subOrder_cart`
        this.$router.push(url)
      } else {
        this.$toast({ message: '请先添加商品', duration: 1 * 1000 })
      }
    },

    // 去认证
    toUpCertification() { this.$router.push('/home/certification') },

    loginOut() {
      this.$router.push('/user/login')
      var path = this.$route.path
      sessionStorage.setItem('shareUrl', path)
    }
  }
}
</script>

<style>
.top_classfly_tab_cart .van-tabs .van-tab {
  font-size: 0.4rem;
}
.top_classfly_tab_cart .van-tabs .van-tab--active{
  font-size: 0.4rem;
}

</style>
